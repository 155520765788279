import "core-js/stable";
import "whatwg-fetch";
import "sanitize.css";

import("./app").then((app) => {
    app.info();
    app.u3coreInfo();
    app.ucubeConnectApiInfo();
    app.render();
});
